import * as Sentry from "@sentry/react";
import { useCallback, useEffect, useState } from 'react';

import { CircularProgress } from '@mui/material';
import { t } from 'i18next';
import Head from 'next/head';
import { useRouter } from 'next/router';
import CoinIcon from '../../assets/MobileMenu/coin.svg';
import withCountry from '../../components/HigherOrder/withCountry';
import { GridItems, HorizontalItemsScroll, ItemsScrollDefaultHeader } from '../../components/ItemsScroll';
import Skeleton from '../../components/Skeleton';
import { FlatButton } from '../../components/UI/Button/styles';
import { useAuth } from '../../hooks/auth';
import { useHome } from '../../hooks/home';
import { useQuests } from '../../hooks/quests';
import { AdvertiserListItem } from '../../models/home';
import { CompanyQuestsPreview, QuestByCompanySlug } from '../../models/quests';
import { getExtensionVersion, isAppV2 } from '../../services/versionManager';
import { Banner } from '../../styles/pages/home/styles';
import theme from '../../styles/theme';

import joinCointimesDiscordPtBrBanner from '../../assets/Banners/join-discord-pt-br.png';
import joinKannaAirdropPtBrBanner from '../../assets/Banners/join-kanna-airdrop-pt-br.png';
import joinTelegramEnBanner from '../../assets/Banners/join-telegram-en.png';
import joinTelegramPtBrBanner from '../../assets/Banners/join-telegram-pt-br.png';
import joinXtimesAirdropEnBanner from '../../assets/Banners/join-xtimes-airdrop-en.png';
import joinXtimesAirdropPtBrBanner from '../../assets/Banners/join-xtimes-airdrop-pt-br.png';
import xtimesSeason2HasStartedEnBanner from '../../assets/Banners/xtimes-airdrop-season2-started-en.png';
import xtimesSeason2HasStartedPtBrBanner from '../../assets/Banners/xtimes-airdrop-season2-started-pt-br.png';

import { faMinusSquare, faPlusSquare } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon as FAIcon } from "@fortawesome/react-fontawesome";
import Markdown from "markdown-to-jsx";
import i18n from "../../i18n";
import { isOnPopQuest } from '../../utils/app';
import { StaticImageData } from "next/image";

const IssueReport: React.FC<React.PropsWithChildren<{ title: string, body: string }>> = ({ title, body }) => {
  const [showContent, setShowContent] = useState(false);

  return (
    <>

      <div>
        <button style={{ color: 'white', display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }} onClick={() => setShowContent(!showContent)}>
          <FAIcon icon={showContent ? faMinusSquare : faPlusSquare} color="white" />
          <Markdown>{title}</Markdown>
        </button>
        {showContent && <Markdown style={{ padding: '10px', marginLeft: '10px' }}>{body}</Markdown>}
      </div>
    </>
  )
}

const issues: { title: string, body: string }[] = [
  //   {
  //     title: '### Continua a manutenção para garantir os saldos corretos ℹ️',
  //     body: `
  // - Parte do problema foi solucionado, porém a manutenção continua para garantir a corretude do sistema.
  // - **Nenhum** BTC adquirido anteriormente será perdido! ⚠️
  // - **Nenhum** TON adquirido será perdido! ⚠️
  // - Os saques permanecerão desativados até a solução completa do problema. ℹ️
  // <br/><br/>
  // **ATENÇÃO!**: Pedidos de saques abertos na tentativa de explorar o problema, foram cancelados! 👈
  // `
  //   },
  //   {
  //     title: '###  Pontuações do Airdrop foram corrigidas ℹ️',
  //     body: `
  // - Houve um incidente que ocasionou muitos pontos sendo adquiridos por conta do problema com o saldo de BTC.
  // - Todos os pontos indevidos foram removidos e o rank voltou a sua normalidade.`
  //   },
//   {
//     title: '###  Saldos e rank do Airdrop XTimes corrigidos! ℹ️',
//     body: `
// - **Nenhum** valor em **BTC** e/ou **TON** foi perdido! ⚠️
// - Ganhos dos dias **12**, **13** e **14** já foram pagos aos usuários que participaram das missões. 💰
// - Todos os valores das missões da **TON** estão armazenados na carteira do usuário e **será adicionado** uma forma de visualizar esse saldo. 🏗️
// <br/><br/>
// **ATENÇÃO!**: Os saques permanecerão **desativados** até o final da tarde de **Seg. 16 de Dezembro**. 👈
// `
//   },
//   {
//     title: '### Saques de `BTC` e `BRL` estão ativos novamente! ℹ️',
//     body: `
// - O saque está novamente disponível para as moedas **BTC** e **BRL**. 🏦
// - Ganhos dos dias **15** e **16** foram pagos aos usuários que participaram das missões. 💰
// <br/><br/>
// **ATENÇÃO!**: O saque de **TON** não está implementado ainda. Todas as quantias ganhas em **TON** estão seguras em sua carteira **Cointimes**. 👈
// `
//   },
//   {
//     title: '### Sorteios e novidades sobre a TON no Discord da **Cointimes** 🎉',
//     body: `
// **Confirme sua presença**
// <br/>
// 📅 Data: **Sexta-feira, 20 de Dezembro**
// <br/>
// ⏰ Horário: **09h00** (_Horário de Brasília_)
// <br/>
// 🎧 Plataforma: **Comunidade Cointimes** no **Discord**
// <br/>
// 📢 Anfitrião: **Isac Honorato** (_Fundador e CEO no Cointimes_)
// <br/><br/>
// Chegou o momento da nossa última "live" de 2024, e vamos fechar o ano com chave de ouro! 💥
// <br/>
// Vai ser um evento muito especial, onde vamos nos reunir diretamente no **Discord** para sorteios, bate-papo e muita troca de ideias!
// <br/><br/>
// #####🔹 Sorteios que vão rolar!
// - 50 dólares em prêmio 💸
// - 2 camisetas exclusivas 👕
// - Pontos de airdrop 💎
// <br/><br/>

// #####🔹 O que vai rolar?
// - Bate-papo sobre como estão funcionando as missões da **TON** e como você pode aproveitar ao máximo essa oportunidade!
// - O que é a **TON** e por que ela está ganhando destaque no mercado? 🤔
// -  Stake na **Foxbit** 💰
//  - Descubra como colocar suas **TON** em stake e gerar ganhos! 📈
// - Esclarecimento de dúvidas, dicas e muito mais!
// <br/><br/>
// 💬 Vai ser uma oportunidade única para aprender sobre as missões da **TON**, ganhar prêmios e se conectar com a cada vez mais com a gente!
// <br/><br/>
// **Como confirmar presença?**
// - Entre na **[nossa comunidade no Discord 🔗 👈](https://discord.com/invite/cointimes)**
// - Confira os eventos no topo superior esquerdo e clique no botão **"Me Interessa"**.
// - Pronto, agora é só aguardar a data do evento.
// <br/><br/>
// **Como participar?**
// - Acesse o **[evento no Discord 🔗 👈](https://discord.gg/PWcrUv4b?event=1318619346171596930)**
// <br/><br/>
// **ATENÇÃO!**: O saque de **TON** não está implementado ainda. Todas as quantias ganhas em **TON** estão seguras em sua carteira **Cointimes**. 👈
// `
//   },
//   {
//     title: '### Ganhe **satoshis** participando do **_ICP HUBS - Global Townhall 3.0_** no **X** 📞🤷',
//     body: `
// **Confirme sua presença**
// <br/>
// 📅 Data: **Sexta-feira, 20 de Dezembro**
// <br/>
// ⏰ Horário: **14h00** (_Horário de Brasília_)
// <br/>
// 🎧 Plataforma: **Spaces ICP** no **X** (_Anteriormente Twitter_)
// <br/>
// 📢 Anfitrião: **Thiago Manesco** (_ICP HUB Brasil Core Team_)
// <br/><br/>
// Participe do evento global de 24h da **ICP** para encerrar 2024 com chave de ouro, conectando todos os HUBs da **ICP** no **_Global Townhall_** de Natal. ✨
// <br/>
// Conecte-se 👥, aprenda 📚 e explore 🔍 o que está por vir no mundo da **ICP**. 🌍
// <br/><br/>
// O **ICP HUB Brasil** 🇧🇷 terá seu momento no Super Spaces, com um time de speakers incríveis discutindo temas essenciais para o futuro da **Web3** e da **ICP**.
// <br/><br/>
// ##### 🎯 Principais temas abordados
// - Projetos incubados pelo HUB e Business Web3.
// - Inteligência Artificial e seus Agentes.
// - Memecoins: A narrativa para 2025?
// - O que esperar para o ano de 2025?
// - Quais tendências dominarão o mercado?
// <br/><br/>

// ##### 💸 Participe e ganhe no Cointimes
// - Aquecimento para o evento:&nbsp;&nbsp;(_até **35 satoshis**_)
//  -  &nbsp;&nbsp;&nbsp;&nbsp;2 novas missões da **ICP** para ganhar.
// - Durante o evento:&nbsp;&nbsp;(**50 satoshis**)
//  -  &nbsp;&nbsp;&nbsp;&nbsp;1 nova missão que te levará até o evento ao vivo para aprender com a **ICP**.
// <br/><br/>
// _Última chance de ganhar com as [missões da **ICP** 🔗](/quests/icp-brasil) aqui no **Cointimes** em 2024. Não perca essa oportunidade!_ 👈
// <br/><br/>
// 💡 Prepare-se para uma celebração cheia de troca de ideias, conexões globais e muito aprendizado.
// <br/>
// &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Acesse o **[X 🔗](https://x.com/icphub_BR/status/1869413023695188349)** da **ICP HUB Brasil** para saber mais, marque na agenda e participe dessa jornada incrível. 🎉
// <br/><br/>
// **Como marcar na agenda?**
// - Entre no [space do **X** 🔗](https://bff.prod.coingoback.com/api/quest-redirection?redirect_to_mobile=x%3A%2F%2Fspaces%2F1ypJdpvQeYyJW&redirect_to_web=https%3A%2F%2Fx.com%2Fi%2Fspaces%2F1ypJdpvQeYyJW%2Fpeek&time=1) onde ocorrerá o evento
// - Clique em **'Receber lembrete'** ou **'Adicionar ao calendário'**
// <br/><br/>
// **Como participar?**
// - Durante o evento, vá até as [missões da **ICP** 🔗](/quests/icp-brasil)
// - Clique na única missão disponível
// - Pronto! Você irá obter sua recompensa e ser levado até o evento. 💸✨
// <br/><br/>
// _A missão levando ao evento estará disponível **apenas** durante o evento._ 👈
// `
  // }
]

const IssueTracker: React.FC<React.PropsWithChildren<unknown>> = () => {
  return (
    <>
      <div style={{ padding: '0px 20px' }}>
        <div style={{ backgroundColor: 'black', color: 'white', padding: '20px', borderRadius: '20px', border: '2px solid #FE501C', display: 'flex', flexDirection: 'column', gap: '15px' }}>
          {issues.map((v, i) => <IssueReport key={i} title={v.title} body={v.body} />)}
        </div>
      </div>
    </>
  )
}

const Home: React.FC<React.PropsWithChildren<unknown>> = () => {
  const router = useRouter();
  const { localCountry } = useAuth();

  const { getAdvertisers } = useHome();
  const [featuredStores, setFeaturedStores] = useState<AdvertiserListItem[]>([]);
  const [canDisplayStores, setCanDisplayStores] = useState<boolean>(false);

  const [isLoadingFeaturedStores, setIsLoadingFeaturedStores] = useState(true);
  const [currentLoadingStoreIndex, setCurrentLoadingStoreIndex] = useState(null);

  const { getCompanyQuestPreviews, getQuestsByCompanySlug } = useQuests();
  const [companyQuestPreviews, setCompanyQuestPreviews] = useState<CompanyQuestsPreview[]>([]);
  const [isCompanyQuestPreviews, setIsCompanyQuestPreviews] = useState(true);

  const [readToEarnList, setReadToEarnList] = useState<QuestByCompanySlug[]>([]);

  const [isLoadingReadToEarnList, setIsLoadingReadToEarnList] = useState(true);
  const [isLoadingMoreReadToEarnList] = useState(false);

  const [readToEarnLoadingCount, setReadToEarnLoadingCount] = useState(20);

  const bannersTop = useCallback<() => {image: StaticImageData|string, link: string, backgroundColor?: string}[]>(
    () => localCountry == 'BR' ? [
      isAppV2() ? {
        image: 'https://cointimes.com.br/wp-content/uploads/2025/02/Banner-Caca-Palavras-1500x584-1.png',
        link: '/open-in-app/reward/welcome',
      } : {
        image: 'https://cointimes.com.br/wp-content/uploads/2025/02/Banner-Temporada2-1500x584-3.png',
          link: '/airdrop/xtimes/season',
      },
      // {
      //   image: '',
      //   link: isAppV2()
      //     ? '/open-in-app/reward/welcome'
      //     : ''
      // }
      // {
      //   image: 'https://cointimes.com.br/wp-content/uploads/2025/02/Banner-Live-RWA.png',
      //   link: isAppV2()
      //     ? '/open-in-app/coingoback-quest-navigation/quests/4afe59d1-204e-4b25-8784-72b78635c95b'
      //     : 'https://bff.prod.coingoback.com/enable-quest?quest_id=4afe59d1-204e-4b25-8784-72b78635c95b'
      // }
      // {
      //   image: xtimesSeason2HasStartedPtBrBanner,
      //   link: '/airdrop/xtimes/season',
      // },
      // {
      //   image: joinKannaAirdropPtBrBanner,
      //   link: '/airdrop/kanna',
      // },
      // {
      //   image: joinCointimesDiscordPtBrBanner,
      //   link: 'https://discord.gg/cointimes',
      // }
    ] : [
      {
        image: xtimesSeason2HasStartedEnBanner,
        link: '/airdrop/xtimes/season',
      }
    ], [localCountry]);

  // const mobileBannerTop = {
  //   image: 'https://cointimes.com.br/wp-content/uploads/2023/11/comunidade_whatsapp.jpeg',
  //   link: 'https://chat.whatsapp.com/GmfEVpGlZ0jJpws2Sjc21b',
  //   backgroundColor: '#a3fac5'
  // };

  const getRandomBannerTop = useCallback(() => {
    const banners = bannersTop();
    return banners[Math.floor(Math.random() * banners.length)]
  }, [bannersTop]);

  const [bannerTopUrl, setBannerTopUrl] = useState(getRandomBannerTop());

  const [extensionVersion, setExtensionVersion] = useState(null);

  useEffect(() => {
    if (!isAppV2()) {
      return;
    }

    window.addEventListener('onPopInAppPage', handleOnPopInAppPage);

    return () => {
      window.removeEventListener('onPopInAppPage', handleOnPopInAppPage);
    };
  }, []);

  const handleOnPopInAppPage = (event) => {
    if (isOnPopQuest(event)) {
      setReadToEarnList(prevReadToEarnList => {
        const newReadToEarnList = [...prevReadToEarnList];
        const index = newReadToEarnList.findIndex(readToEarn => readToEarn.id === event.detail.dataFromPage.quest.id);

        if (index !== -1) {
          newReadToEarnList[index].is_rewarded = event.detail.dataFromPage.quest.is_rewarded;
          setReadToEarnList(newReadToEarnList);
        }

        return newReadToEarnList;
      });
    }
  };

  useEffect(() => {
    async function getAdvertisersInitialData() {
      try {
        const featuredStoresData = await getAdvertisers(19, 0);

        setFeaturedStores(featuredStoresData ?? []);
        setIsLoadingFeaturedStores(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    if (localCountry === 'BR') {
      setCanDisplayStores(true);
      getAdvertisersInitialData();
    }
  }, [getAdvertisers, localCountry]);

  useEffect(() => {
    getExtensionVersion().then((value) => {
      setExtensionVersion(value);
    });
  }, []);

  useEffect(() => {
    async function getCompanyQuestPreviewsInitialData() {
      try {
        const companyQuestPreviews = await getCompanyQuestPreviews();

        setCompanyQuestPreviews(companyQuestPreviews ?? []);
        setIsCompanyQuestPreviews(false);
      } catch (error) {
        console.log(error);
        Sentry.captureException(error);
      }
    }

    setBannerTopUrl(getRandomBannerTop());

    // const isMobile = localStorage.getItem('@coingoback:isMobile') === 'true';
    // if (isMobile) {
    //   setBannerTopUrl(mobileBannerTop);
    // }else{
    //   setInterval(() => {
    //       setBannerTopUrl(getRandomBannerTop());
    //   }, 8000);
    // }

    getCompanyQuestPreviewsInitialData();
  }, [getCompanyQuestPreviews, getRandomBannerTop]);

  const getNewsInitialData = useCallback(async (limit?: number, offset?: number) => {
    try {
      const readToEarnList = await getQuestsByCompanySlug('read-to-earn', limit, offset);

      setReadToEarnList(readToEarnList ?? []);
      setIsLoadingReadToEarnList(false);
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
    }
  }, [getQuestsByCompanySlug]);

  useEffect(() => {
    if (router.isReady && readToEarnList.length === 0) {
      // let page = 1;

      // if(!Array.isArray(router.query.page) && isAppV2()) {
      //   page = parseInt(router.query.page);
      // }

      const limit = 200;

      setReadToEarnLoadingCount(limit);
      getNewsInitialData(limit, 0);
    }
  }, [getNewsInitialData, readToEarnList.length, router.isReady]);

  // async function loadMoreNewsData() {
  //   try {
  //     setIsLoadingMoreReadToEarnList(true);

  //     const loadedMoreReadToEarnList = await getQuestsByCompanySlug('read-to-earn', 20, readToEarnList.length);
  //     setReadToEarnList(readToEarnList.concat(loadedMoreReadToEarnList));

  //     setIsLoadingMoreReadToEarnList(false);
  //   } catch (error) {
  //     console.log(error);
  //     Sentry.captureException(error);
  //   }
  // }

  const handleStoreClick = (slug: string) => {
    router.push({
      pathname: `store/${slug}`,
    });
  };

  const handleQuestCompanyClick = (slug: string) => {
    router.push({
      pathname: `quests/${slug}`,
    });
  };

  useEffect(() => {
    // Dynamically load the GPT library
    const script = document.createElement('script');
    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';
    script.async = true;
    document.head.appendChild(script);

    script.onload = () => {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(function () {
        window.googletag.defineSlot('/21958300909/cointimes.com.br/cointimes_mobile_horizontal_top_economia', [[320, 50], [300, 100]], 'div-gpt-ad-1709682267168-0').addService(window.googletag.pubads());
        window.googletag.defineSlot('/21958300909/cointimes.com.br/cointimes_mobile_horizontal_top_blog', [[300, 100], [320, 50]], 'div-gpt-ad-1709682199466-0').addService(window.googletag.pubads());
        window.googletag.pubads().enableSingleRequest();
        window.googletag.enableServices();
      });

      window.googletag.cmd.push(function () { window.googletag.display('div-gpt-ad-1709682267168-0'); });
      window.googletag.cmd.push(function () { window.googletag.display('div-gpt-ad-1709682199466-0'); });
    };
    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <>
      <Head>
        <title>{t('pages.home.earn-bitcoin')} | Cointimes</title>
        <meta
          name="description"
          content={t('pages.home.earn-bitcoin')}
        />
      </Head>
      <div style={{ display: 'flex', flexDirection: 'column', maxWidth: '100vw', gap: 40, margin: '20px 0px 20px 0px', WebkitTapHighlightColor: 'transparent' }}>

        {i18n.language === 'pt-BR' && issues.length > 0 && <IssueTracker />}
        <Banner $backgroundColor={bannerTopUrl?.backgroundColor ?? '#000'}>
          <a target={bannerTopUrl.link.startsWith('/open-in-app/') ? '_self' : '_blank'} href={bannerTopUrl.link} rel="noreferrer">
            <img
              src={typeof (bannerTopUrl.image) === 'string' ? bannerTopUrl.image : bannerTopUrl.image.src}
              alt="banner"
            />
          </a>
        </Banner>
        {/* <Banner backgroundColor={'#aaa6'}>
          <div id='div-gpt-ad-1709682267168-0' style={{ minWidth: '300px', minHeight: '50px', textAlign: 'center' }}></div>
        </Banner> */}
        <HorizontalItemsScroll
          header={(props) =>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <ItemsScrollDefaultHeader
                elements={props.elements}
                headerText={t('pages.home.complete-quests-and-earn')}
                headerSeeAllColor={theme.colors.primary.main}
              />
            </div>
          }
          gap={15}
        >
          {isCompanyQuestPreviews ?
            Array.from(Array(15)).map((_, idx) => {
              return <Skeleton
                key={idx}
                style={{
                  minHeight: 80,
                  minWidth: 80,
                  borderRadius: '50%',
                  marginLeft: idx === 0 ? 20 : 0,
                  marginRight: idx === 14 ? 20 : 0,
                }}
              ></Skeleton>
            }) :

            companyQuestPreviews.map((companyQuestPreview, idx) => {
              return <button
                key={idx}
                style={{
                  marginLeft: idx === 0 ? 20 : 0,
                  marginRight: idx === companyQuestPreviews.length - 1 ? 20 : 0,
                }}
                onClick={() => {
                  if (companyQuestPreview.id === '6891bf0b-b499-4846-aa8f-da29e0ee6700') {
                    router.push('/airdrop/xtimes/season');
                  } else if (companyQuestPreview.id === 'befc36ba-b1a1-4c00-aa28-7045b1f8ddec') {
                    router.push('/airdrop/kanna');
                  } else if (companyQuestPreview.slug === 'read-to-earn') {
                    router.push('/read-to-earn');
                  } else {
                    handleQuestCompanyClick(companyQuestPreview.slug);
                  }
                }}
              >
                <div style={{
                  minHeight: 80,
                  minWidth: 80,
                  borderRadius: '50%',
                  border: 'solid black 2px',
                  backgroundImage: "url('" + companyQuestPreview.image_small + "')",
                  backgroundSize: 'cover',
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center center',
                }}>
                </div>
              </button>
            })
          }
        </HorizontalItemsScroll>

        {canDisplayStores ?

          <HorizontalItemsScroll
            header={(props) =>
              <div style={{ paddingLeft: 20, paddingRight: 20 }}>
                <ItemsScrollDefaultHeader
                  elements={props.elements}
                  headerText={t('pages.home.buy-and-earn')}
                  headerSeeAllColor={theme.colors.primary.main}
                  onSeeAllClick={() => {
                    router.push('/stores');
                  }}
                />
              </div>
            }
            gap={15}
          >
            {isLoadingFeaturedStores ?
              Array.from(Array(10)).map((_, idx) => {
                return <Skeleton
                  key={idx}
                  style={{
                    minHeight: 190,
                    minWidth: 200,
                    marginLeft: idx === 0 ? 20 : 0,
                    marginRight: idx === 9 ? 20 : 0,
                    borderRadius: '15px',
                  }}
                ></Skeleton>

              }) :

              featuredStores.map((featuredStore, idx) => {
                return <div
                  key={idx}
                  style={{
                    minHeight: 190,
                    minWidth: 200,
                    marginLeft: idx === 0 ? 20 : 0,
                    marginRight: idx === featuredStores.length - 1 ? 20 : 0,
                    borderRadius: '15px',
                    border: 'solid black 2px',
                    padding: 10,
                  }}
                ><button
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '100%',
                  }}
                  onClick={() => {
                    setCurrentLoadingStoreIndex(idx);
                    handleStoreClick(featuredStore.slug);
                  }}
                >
                    <div style={{
                      minHeight: 100,
                      borderRadius: '15px',
                      border: 'solid black 2px',
                      backgroundImage: "url('" + featuredStore.image_url + "')",
                      backgroundSize: 'cover',
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center center',
                      width: '100%'
                    }}></div>
                    <div style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                      {currentLoadingStoreIndex === idx ?
                        <CircularProgress size={20} style={{ color: 'black' }} />
                        :
                        <>
                          <span style={{ color: 'black' }}>{featuredStore.name}</span>
                          <h3 style={{ color: 'black' }}>{featuredStore.commission_description}</h3>
                        </>
                      }
                    </div>
                  </button>
                </div>
              })
            }
          </HorizontalItemsScroll>

          : <></>}

        {localCountry == 'BR' ?
          <Banner $backgroundColor={'#000000'}>
            <a target="_blank" href="https://discord.gg/cointimes" rel="noreferrer">
              <img
                src={joinCointimesDiscordPtBrBanner.src}
                alt="banner"
              />
            </a>
          </Banner>
          :
          <Banner $backgroundColor={'#000000'}>
            <a target="_blank" href="https://t.me/cointimesapp" rel="noreferrer">
              <img
                src={localCountry == 'BR' ? joinTelegramPtBrBanner.src : joinTelegramEnBanner.src}
                alt="banner"
              />
            </a>
          </Banner>
        }

        {/* <Banner backgroundColor={'#aaa6'}>
          <div id='div-gpt-ad-1709682199466-0' style={{ minWidth: '300px', minHeight: '50px' }}></div>
        </Banner> */}

        <GridItems
          header={(props) =>
            <div style={{ paddingLeft: 20, paddingRight: 20 }}>
              <ItemsScrollDefaultHeader
                elements={props.elements}
                headerText={t('pages.home.daily-read-to-earn')}
              />
            </div>
          }
          gap={15}
          gridMinPx={300}
          padding='0px 15px'
        >
          {isLoadingReadToEarnList && readToEarnLoadingCount ?
            Array.from(Array(readToEarnLoadingCount)).map((_, idx) => {
              return <div
                key={idx}
                style={{
                  minHeight: 300,
                  height: 300,
                  width: '100%',
                }}>
                <Skeleton style={{
                  height: '100%',
                  width: '100%',
                  borderRadius: '15px',
                }}></Skeleton>
              </div>
            }) :

            readToEarnList.map((readToEarn) => {
              return <div
                key={readToEarn.id}
                style={{
                  minHeight: 300,
                  height: 300,
                  width: '100%',
                  borderRadius: '15px',
                  border: 'solid black 2px',
                  padding: 10,
                }}
              ><button
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                  height: '100%',
                }}
                onClick={() => {
                  if (isAppV2()) {
                    window.location.href = '/open-in-app/coingoback-quest-navigation/quests/' + readToEarn.id;
                  } else if (extensionVersion !== null) {
                    if (extensionVersion === '2.3.2') {
                      window.open(readToEarn.quest_link.initial_url, '_blank');
                    } else {
                      window.open(`${process.env.NEXT_PUBLIC_API_URL.replace('/graphql', '')}/enable-quest?quest_id=${readToEarn.id}`, '_blank');
                    }
                  }
                }}
              >
                  <div style={{
                    minHeight: 150,
                    borderRadius: '15px',
                    border: 'solid black 2px',
                    backgroundImage: "url('" + readToEarn.image + "')",
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center center',
                    width: '100%'
                  }}></div>
                  <div style={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', gap: 8 }}>
                    <h3 style={{ marginTop: 20, textAlign: 'left', color: 'black' }}>{readToEarn.title.substring(0, 69) + (readToEarn.title.length > 70 ? '...' : '')}</h3>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <div style={{ border: 'solid black 1px', borderRadius: '8px', padding: '5px 10px', display: 'flex', alignItems: 'center', backgroundColor: readToEarn.is_rewarded === true ? 'white' : theme.colors.primary.main }}>
                        {readToEarn.reward_currency === 'BTC' ?
                          <CoinIcon style={{ filter: 'brightness(0)', marginRight: 3, width: 12, height: 12 }} />
                          : <></>}
                        <span style={{ color: 'black' }}>{readToEarn.reward_amount_formatted}</span>
                      </div>
                    </div>
                  </div>
                </button>
              </div>
            }).concat(
              isLoadingMoreReadToEarnList ? Array.from(Array(20)).map((_, idx) => {
                return <div
                  key={idx}
                  style={{
                    minHeight: 300,
                    height: 300,
                    width: '100%',
                  }}
                >
                  <Skeleton style={{
                    height: '100%',
                    width: '100%',
                    borderRadius: '15px',
                  }}></Skeleton>
                </div>
              }) : []
            )
          }
        </GridItems>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', padding: '0px 15px' }}>
          <div
            style={{
              minHeight: 300,
              height: 300,
              width: '100%',
              borderRadius: '15px',
              border: 'solid black 2px',
              padding: 10,
              backgroundColor: 'black',
            }}
          ><div
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          >
              <h1 style={{ marginTop: 20, textAlign: 'center', color: '#F44805' }}>{t('pages.read-to-earn.wow-title')}</h1>
              <h3 style={{ marginTop: 20, textAlign: 'center', color: 'white' }}>{t('pages.read-to-earn.daily-read-to-earn-finished')}</h3>
              <h4 style={{ marginTop: 10, textAlign: 'center', color: 'white', fontWeight: 'normal' }}>{t('pages.read-to-earn.want-to-learn-more')}</h4>

              <FlatButton
                style={{ marginTop: 20, padding: 20 }}
                onClick={() => {
                  window.open(t('pages.read-to-earn.earn-more-link'), '_blank');
                }}
              >
                {t('pages.read-to-earn.i-want-to-earn')}
              </FlatButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withCountry(Home);
