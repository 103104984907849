import { useEffect, useState } from "react";
import { useAuth } from "../../hooks/auth";
import getTimezoneCountry from "../../utils/getTimezoneCountry";
import i18n from "../../i18n";

function withCountry<P>(WrappedComponent: React.ComponentType<React.PropsWithChildren<P>>) {
  const WithCountry = (props: P) => {
    const { user } = useAuth();
    const [ hasCountry, setHasCountry ] = useState(false);

    useEffect(() => {
      if (typeof window === 'undefined') {
        return;
      }

      const localToken = localStorage.getItem('@coingoback:token');
      let country = null;
      if (user) {
        if (user.country == null) {
          window.location.href = '/country-selection';
          return;
        }

        country = user.country;
      } else if (!localToken) {
        country = getTimezoneCountry();
      }

      if (country) {
        localStorage.setItem('@coingoback:localCountry', country);

        if (country == 'BR') {
          i18n.changeLanguage('pt-BR')
        }
      }

      setHasCountry(true);

    }, [user]);

    return !hasCountry ? <></> : <WrappedComponent {...props} />;
  };

  WithCountry.displayName = `WithCountry(${WrappedComponent.displayName || WrappedComponent.name || 'Component'})`;

  return WithCountry;
};

export default withCountry;
